.container {
  margin: 1em auto;
  background-color: #fff;
  padding: 1em;
  max-width: 800px;
  line-height: 1.5;
}

.code {
  background: #eee;
  padding: 0 0.25em;
}

.logo {
  text-align: center;
}

.header {
  &:hover::after {
    content: ' §';
  }
}

.headerLink {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
