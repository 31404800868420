$fg-color: #222;
$bg-color: white;
$vertical-border-color: #cacaca;
$arrow-height: 10px;
$arrow-width: 20px;

.container {
  z-index: 10;
  font-size: 12px;

  & button:enabled {
    cursor: pointer;
  }
}

.-content {
  margin: $arrow-height;
  box-shadow:
    5px 5px 20px -3px rgb(0 0 0 / 25%),
    0 0 5px -2px rgb(0 0 0 / 90%);
  border-radius: var(--header-border-radius);
  background: $bg-color;
  color: $fg-color;
}

.contentBottom {
  composes: -content;
  margin-top: 0;
}

.contentTop {
  composes: -content;
  margin-bottom: 0;
}
