:root {
  --primary-font: 'Open Sans', sans-serif;
  --rust: #a42;
  --rust-dark: #80331a;
  --border-color: #bbb;
  --border: 1px solid var(--border-color);
  --header-main-border: #dedede;
  --header-transition: 0.2s ease-in-out;
  --header-tint: #428bca;
  --header-border-radius: 4px;
  --header-accent-border: #bdbdbd;

  /* The big red button */

  --button-primary-color: white;
  --button-primary-bg-color: var(--rust);
  --button-primary-border-color: var(--rust-dark);
  --button-primary-bg-color-light: color-mix(in hsl, var(--button-primary-bg-color), white);
  --button-primary-border-color-light: color-mix(in hsl, var(--button-primary-border-color), white);

  /* Clicked */
  --button-primary-active-color: color-mix(in hsl, white, black 30%);

  /* Not the big red button */

  --button-secondary-color: #444;
  --button-secondary-bg-color-top: #fff;
  --button-secondary-bg-color-bottom: #f9f9f9;
  --button-secondary-border-color: color-mix(
    in hsl,
    var(--button-secondary-bg-color-bottom),
    black 20%
  );

  /* Disabled */
  --button-secondary-bg-color-light: color-mix(
    in hsl,
    var(--button-secondary-bg-color-bottom),
    white
  );
  --button-secondary-border-color-light: color-mix(
    in hsl,
    var(--button-secondary-border-color),
    white
  );
  --button-secondary-color-light: color-mix(in hsl, var(--button-secondary-color), white);

  /* Clicked */
  --button-secondary-active-color: color-mix(in hsl, black, white 30%);
}

/* Modify normalized styles */
button,
input,
optgroup,
select,
textarea {
  color: text;
  font-family: var(--primary-font);
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #e1e1db;
  padding: 0 1em;
  font-family: var(--primary-font);
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.language-rust_errors) {
  & :global(.warning) {
    color: #f79a06;
  }

  & :global(.error) {
    color: #bf1b1b;
  }
}
