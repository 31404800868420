$buffer: 0.5em;
$space: 0.25em;

.container {
  position: absolute;
  right: $buffer;
  bottom: $buffer;
  left: $buffer;
  z-index: 10;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid #428bca;
  background: white;
  max-width: 50em;
}

.notification {
  display: flex;
}

.notificationContent {
  padding: $space 0 $space $space;
}

.close {
  composes: -buttonReset from './shared.module.css';
  cursor: pointer;
  background: #e1e1db;
  padding: $space;
}

.action {
  display: flex;
  justify-content: center;
  padding-top: 0.5em;
  gap: 0.5em;
}
