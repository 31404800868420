.form {
  display: flex;
  gap: 0.25em;
  align-items: flex-start;
}

.multiLine {
  flex: 1;
  display: grid;
}

.text,
.sizer {
  margin: 0;
  padding: 0.25em;
  grid-area: 1 / 1 / 2 /2;
  max-height: 3.5lh;
}

.text {
  resize: none;
}

.sizer {
  visibility: hidden;
  white-space: pre-wrap;
}

.buttons {
  font-size: 11px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.button {
  composes: -menuItemFullButton from './shared.module.css';
  padding: 1em;

  &:hover {
    color: var(--header-tint);
  }
}
