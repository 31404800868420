.container,
.left,
.right {
  display: flex;
  gap: 0.5em;
}

.container {
  font-size: 12px;
  padding: 1.25em 0;
  justify-content: space-between;
}
