$current-tab: #f9ffff;
$background-tab: #fcfcfc; /* desaturate($current-tab, 100%); */

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tabs {
  display: flex;
}

.tab {
  flex: 1 1 auto;
  cursor: pointer;
  border: var(--border);
  border-right: none;
  background-color: $background-tab;
  line-height: 1.5em;

  &:last-of-type {
    border-right: var(--border);
  }
}

.tabSelected {
  composes: tab;
  cursor: default;
  border-bottom: none;
  background-color: $current-tab;

  &:focus {
    outline: none;
  }
}

.tabClose {
  composes: tab;
  flex: 0 0 auto;
}

.body {
  border: var(--border);
  border-top: none;
  background-color: $current-tab;
  padding: 0.5em;
  height: 100%;
  overflow: scroll;
}

.stdin {
  margin-top: 0.25em;
}
